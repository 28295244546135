import React from 'react';
import { graphql, Link } from 'gatsby';
import { merge, Box, Text, Grid } from 'theme-ui';
import Seo from '@solid-ui-components/Seo';
import Layout from '@solid-ui-layout/Layout';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from '../new-landing/_theme';
import ConvertTextToComponent from '../../../content/components/ConvertTextToComponent';
import SupportWrapper from '../../components/Support/components/SupportWrapper';
import ContentfulSVG from '../../../content/components/ContentfulSVG/ContentfulSVG';
import { HeaderLogo } from '../../@elegantstack/flow-ui-layout/Header/Header.Logo';

const styles = {
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 50,
  },
  box: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 19px -7px rgba(0, 0, 0, 0.25)',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    transition: '0.4s',
    border: 'none',
    padding: 25,
    textAlign: 'center',
    // fontSize: 16,
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
  catalog: {
    marginTop: [50, 110],
    marginBottom: 50,
  },
  catalogBox: {
    height: 275,
    flexDirection: 'column',
  },
  imageWrapper: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    // objectFit: 'contain',
    width: '100% !important',
    height: '150px !important',
  },
  text: {
    margin: '40px 0',
  },
};

const Support = (props) => {
  const {
    allBlockContent,
    faqCategories,
    remainingFaqQuestions,
    allFaqQuestions: { nodes: allFaqQuestions },
    featuredFaqQuestions,
  } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title='Support' />
      <Header content={content['header']} />
      <SupportWrapper
        title={content['support-index']?.kicker.text}
        placeholder={content['faq-categories']?.description.textGroup[0]}
        searchData={allFaqQuestions}
        footer={
          <ConvertTextToComponent
            content={content['support-index'].description.detailedTexts}
            marginX='4'
            marginY='2'
          />
        }
      >
        <Box>
          <Text variant='h2' sx={styles.header}>
            {content['support-index']?.text_1?.text}
          </Text>
          <Grid gap={[2, 4]} columns={[1, 2, 3]}>
            {[...featuredFaqQuestions.nodes, ...remainingFaqQuestions.nodes]
              .slice(0, 6)
              .map(({ title, slug, category }) => (
                <Box
                  as={Link}
                  to={`/support/${category.slug}/${slug}`}
                  sx={styles.box}
                >
                  {title}
                </Box>
              ))}
          </Grid>
        </Box>
        <Box sx={styles.catalog}>
          <Text variant='h2' sx={styles.header}>
            {content['support-index']?.text_2?.text}
          </Text>
          <Grid gap={[2, 4]} columns={[1, 2, 3]}>
            {faqCategories.nodes.map(({ slug, name, image }, index) => (
              <Box
                as={Link}
                to={`/support/${slug}`}
                key={`${slug}-box-catalogBox`}
                sx={{ ...styles.box, ...styles.catalogBox }}
              >
                <Box key={`${slug}-box-imageWrapper`} sx={styles.imageWrapper}>
                  {index === 0 ? (
                    <HeaderLogo content={content?.['header']} amp={false} />
                  ) : (
                    <ContentfulSVG sx={styles.image} image={image} />
                  )}
                </Box>
                <Text key={`${slug}-text`} sx={styles.text} variant='small'>
                  {name}
                </Text>
              </Box>
            ))}
          </Grid>
        </Box>
      </SupportWrapper>
      <Footer content={content['footer']} />
    </Layout>
  );
};

export const query = graphql`
  query siteSupportBlockContent($blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: ["footer", "header", "support-index", "faq-categories"]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
    faqCategories: allFaqCategory(sort: { order: ASC, fields: index }) {
      nodes {
        ...FaqCategory
      }
    }
    featuredFaqQuestions: allFaqQuestion(
      filter: { featured: { eq: true } }
      sort: { fields: index, order: ASC }
      limit: 6
    ) {
      nodes {
        title
        slug
        category {
          slug
        }
      }
    }
    remainingFaqQuestions: allFaqQuestion(
      limit: 6
      sort: { fields: index, order: ASC }
    ) {
      nodes {
        title
        slug
        category {
          slug
        }
      }
    }
    allFaqQuestions: allFaqQuestion(sort: { fields: index, order: ASC }) {
      nodes {
        title
        slug
        category {
          name
          slug
        }
      }
    }
  }
`;
export default Support;
