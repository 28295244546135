import React, { FC } from 'react';
import { Box } from 'theme-ui';
import SVG from 'react-inlinesvg';
import { CSSProperties } from '@emotion/react/node_modules/@emotion/serialize';

type Image = {
  svg: {
    absolutePath: string;
    content: string;
    dataURI: string;
    originalContent: string;
    relativePath: string;
  };
  file: {
    contentType: string;
    fileName: string;
    url: string;
  };
};
export type ContentfulSVGProps = {
  image: Image;
  sx?: CSSProperties;
};
const ContentfulSVG: FC<ContentfulSVGProps> = ({ image, sx }) => {
  if (!image) return <div />;

  if (image.svg && image.svg.content) {
    return (
      <Box
        as='div'
        sx={{ width: '100%', height: '100%', '& svg': { ...sx } }}
        dangerouslySetInnerHTML={{ __html: image.svg.content }}
      />
    );
  }

  return <Box as={SVG} sx={sx} src={image.file.url} />;
};

export default ContentfulSVG;
